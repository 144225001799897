/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { type CSSProperties, useContext, useEffect, useRef, useState } from 'react';

import { cssMap, jsx } from '@compiled/react';
import { bind } from 'bind-event-listener';

import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

import { InteractionSurface } from '../../../components/interaction-surface';
import { useSkipLink } from '../../../context/skip-links/skip-links-context';
import {
	contentHeightWhenFixed,
	contentInsetBlockStart,
	localSlotLayers,
	sideNavVar,
	UNSAFE_sideNavLayoutVar,
} from '../constants';
import { DangerouslyHoistSlotSizes } from '../hoist-slot-sizes-context';
import { DangerouslyHoistCssVarToDocumentRoot } from '../hoist-utils';
import { PanelSplitterProvider } from '../panel-splitter/provider';
import type { ResizeBounds } from '../panel-splitter/types';
import { usePrefixedUID } from '../use-prefixed-id';

import { useSideNavVisibility } from './use-side-nav-visibility';
import {
	useSideNavVisibilityCallbacks,
	type VisibilityCallback,
} from './use-side-nav-visibility-callbacks';
import { useToggleSideNav } from './use-toggle-side-nav';

const panelSplitterResizingVar = '--n_snvRsz';

const sideNavWidthResizeBounds: ResizeBounds = { min: '160px', max: '50vw' };

const styles = cssMap({
	root: {
		backgroundColor: token('elevation.surface.overlay'),
		boxShadow: token('elevation.shadow.overlay'),
		gridArea: 'main / aside / aside / aside',
		// Height is set so it takes up all of the available viewport space minus top bar + banner.
		// Since the side nav is always rendered ontop of other grid items across all viewports height is
		// always set.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		height: contentHeightWhenFixed,
		// This sets the sticky point to be just below top bar + banner. It's needed to ensure the stick
		// point is exactly where this element is rendered to with no wiggle room. Unfortunately the CSS
		// spec for sticky doesn't support "stick to where I'm initially rendered" so we need to tell it.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		insetBlockStart: contentInsetBlockStart,
		position: 'sticky',
		// For mobile viewports, the side nav will take up 90% of the screen width, up to a maximum of 320px (the default SideNav width)
		width: 'min(90%, 320px)',
		// On small viewports the side nav is displayed above other slots so we create a stacking context.
		// We keep the side nav with a stacking context always so it is rendered above main content.
		// This comes with a caveat that main is rendered underneath the side nav content so for any
		// menu dialogs rendered with "shouldRenderToParent" they could be cut off unintentionally.
		// Unfortunately this is the best of bad solutions.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		zIndex: localSlotLayers.sideNav,
		'@media (min-width: 48rem)': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
			width: `var(${panelSplitterResizingVar}, var(${sideNavVar}))`,
		},
		'@media (min-width: 64rem)': {
			backgroundColor: token('elevation.surface'),
			boxShadow: 'initial',
			gridArea: 'side-nav',
		},
	},
	interactionSurface: {
		// Taking full space of container to capture hover interactions on the entire side nav
		width: '100%',
		height: '100%',
	},
	hiddenMobileAndDesktop: {
		display: 'none',
	},
	hiddenMobileOnly: {
		display: 'none',
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
	hiddenDesktopOnly: {
		'@media (min-width: 64rem)': {
			display: 'none',
		},
	},
});

export function SideNav({
	children,
	defaultCollapsed,
	defaultWidth = 320,
	testId,
	label = 'Side Navigation',
	collapseButton,
	onExpand,
	onCollapse,
	id,
}: {
	children: React.ReactNode;
	/**
	 * Whether the side nav should be collapsed by default __on desktop screens__.
	 *
	 * It is always collapsed by default for mobile screens.
	 *
	 * __Note:__ If using this prop, ensure that it is also provided to the `SideNavToggleButton`.
	 * This is to ensure the state is in sync before post-SSR hydration.
	 */
	defaultCollapsed?: boolean;
	defaultWidth?: number;
	testId?: string;
	label?: string;
	/**
	 * The slot for the side nav collapse button. This is used to toggle the visibility of the side nav.
	 */
	collapseButton?: React.ReactNode;
	/**
	 * Called when the side nav is expanded.
	 */
	onExpand?: VisibilityCallback;
	/**
	 * Called when the side nav is collapsed.
	 */
	onCollapse?: VisibilityCallback;
	id?: string;
}) {
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;
	useSkipLink(CID, label);
	const { visibleOnDesktop, visibleOnMobile, setVisibleOnDesktop } = useSideNavVisibility({
		defaultCollapsed,
	});
	const [width, setWidth] = useState(defaultWidth);
	const dangerouslyHoistSlotSizes = useContext(DangerouslyHoistSlotSizes);
	const panelSplitterParentRef = useRef<HTMLDivElement | null>(null);
	const sideNavVariableWidth = `clamp(${sideNavWidthResizeBounds.min}, ${width}px, ${sideNavWidthResizeBounds.max})`;
	const devTimeOnlyAttributes: Record<string, string | boolean> = {};

	// Sync the visibility in context with the local state value after SSR hydration, as it contains the default value from props
	useEffect(() => {
		setVisibleOnDesktop(visibleOnDesktop);
	}, [setVisibleOnDesktop, visibleOnDesktop]);

	useSideNavVisibilityCallbacks({
		onExpand,
		onCollapse,
		visibleOnDesktop,
		visibleOnMobile,
	});

	if (process.env.NODE_ENV !== 'production') {
		const visible: string[] = [];
		if (visibleOnMobile) {
			visible.push('small');
		}
		if (visibleOnDesktop) {
			visible.push('large');
		}
		devTimeOnlyAttributes['data-visible'] = visible.length ? visible.join(',') : 'false';
	}

	const toggleVisibility = useToggleSideNav();

	useEffect(() => {
		const mediaQueryList = window.matchMedia('(min-width: 64rem)');
		return bind(mediaQueryList, {
			type: 'change',
			listener() {
				if (mediaQueryList.matches) {
					// We're transitioning from tablet to desktop viewport size.
					// We forcibly show the side nav if it was shown on mobile.
					if (visibleOnMobile && !visibleOnDesktop) {
						toggleVisibility();
					}
				}
			},
		});
	}, [toggleVisibility, visibleOnDesktop, visibleOnMobile]);

	return (
		<nav
			id={CID}
			{...devTimeOnlyAttributes}
			data-layout-slot
			aria-label={label}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={
				{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
					[sideNavVar]: sideNavVariableWidth,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				} as CSSProperties
			}
			ref={panelSplitterParentRef}
			css={[
				styles.root,
				!visibleOnMobile && visibleOnDesktop && styles.hiddenMobileOnly,
				visibleOnMobile && !visibleOnDesktop && styles.hiddenDesktopOnly,
				!visibleOnMobile && !visibleOnDesktop && styles.hiddenMobileAndDesktop,
			]}
			data-testid={testId}
		>
			{dangerouslyHoistSlotSizes && (
				// ------ START UNSAFE STYLES ------
				// These styles are only needed for the UNSAFE legacy use case for Jira + Confluence.
				// When they aren't needed anymore we can delete them wholesale.
				<DangerouslyHoistCssVarToDocumentRoot
					variableName={UNSAFE_sideNavLayoutVar}
					value="0px"
					mediaQuery={media.above.md}
					responsiveValue={visibleOnDesktop ? sideNavVariableWidth : 0}
				/>
				// ------ END UNSAFE STYLES ------
			)}
			{/**
			 * InteractionSurface is the parent because the PanelSplitterProvider renders a scroll container.
			 *
			 * Using `height: 100%` inside the scroll container will make it the height of the scroll container,
			 * not fill the scroll container.
			 *
			 * We could use `height: max-content` instead but that is not currently an allowed value,
			 * and this is simpler.
			 */}
			<InteractionSurface xcss={styles.interactionSurface}>
				<PanelSplitterProvider
					panelRef={panelSplitterParentRef}
					panelWidth={width}
					onCompleteResize={setWidth}
					resizeBounds={sideNavWidthResizeBounds}
					resizingCssVar={panelSplitterResizingVar}
				>
					{collapseButton}
					{children}
				</PanelSplitterProvider>
			</InteractionSurface>
		</nav>
	);
}
