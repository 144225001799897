import { useCallback, useContext } from 'react';

import { SetSideNavDesktopVisibility, SetSideNavMobileVisibility } from './context';

/**
 * __useToggleSideNav__
 *
 * Returns a function to toggle the side nav visibility.
 *
 * It will toggle the appropriate internal desktop or mobile side nav visibility state based on the current screen size.
 */
export const useToggleSideNav = () => {
	const setVisibleOnDesktop = useContext(SetSideNavDesktopVisibility);
	const setVisibleOnMobile = useContext(SetSideNavMobileVisibility);

	const toggleSideNav = useCallback(() => {
		const { matches } = window.matchMedia('(min-width: 64rem)');
		if (matches) {
			setVisibleOnDesktop((prev) => !prev);
		} else {
			setVisibleOnMobile((prev) => !prev);
		}
	}, [setVisibleOnDesktop, setVisibleOnMobile]);

	return toggleSideNav;
};
