import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import {
	resources as opsReportsResources,
	opsReportsPermissionResource,
} from '@atlassian/jira-router-resources-eoc-ops-reports';
import { resources as teamDashboardResources } from '@atlassian/jira-router-resources-eoc-team-dashboard';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import {
	eocTeamReportsHomePageRoute,
	eocGlobalReportsHomePageRouteNav4,
	eocReportsUnsubscribeRoute,
	eocGlobalReportsNav4Route,
	eocReportsTeamRoute,
} from '@atlassian/jira-router-routes-eoc-reports-routes';
import {
	LazyOpsReportsRedirect,
	LazyOpsReportsUnsubscribe,
	OpsReportsRedirect,
	OpsReportsUnsubscribe,
	GlobalOpsReports,
	LazyGlobalOpsReports,
	OpsReports,
	LazyOpsReports,
} from './ui/spa/eoc/ops-reports';

export const eocReportsTeamRedirectRouteEntry = createEntry(eocTeamReportsHomePageRoute, {
	component: OpsReportsRedirect,

	layout: opsTeamLayout,

	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		...opsReportsResources,
		opsReportsPermissionResource,
		getConsolidationStateResource(),
	],

	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		onlyShowHorziontalOnNav4: true,
	},

	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyOpsReportsRedirect,
		LazyTeamDashboardSidebar,
	],
});

export const eocGlobalReportsRouteNav4Entry = createEntry(eocGlobalReportsHomePageRouteNav4, {
	component: OpsReportsRedirect,

	resources: [
		...getNavigationResources(),
		...opsReportsResources,
		opsReportsPermissionResource,
		getConsolidationStateResource(),
	],

	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
	},

	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyOpsReportsRedirect,
		LazyTeamDashboardSidebar,
	],
});

export const eocGlobalReportsNav4Entry = createEntry(eocGlobalReportsNav4Route, {
	component: GlobalOpsReports,

	resources: [...getNavigationResources(), ...opsReportsResources, getConsolidationStateResource()],

	navigation: {
		menuId: MENU_ID.HOME,
	},

	forPaint: [LazyAtlassianNavigation, LazyGlobalOpsReports],
});

export const eocReportsUnsubscribeEntry = createEntry(eocReportsUnsubscribeRoute, {
	component: OpsReportsUnsubscribe,

	layout: opsTeamLayout,

	resources: [...getNavigationResources()],

	navigation: {
		menuId: MENU_ID.PEOPLE,
	},

	forPaint: [LazyAtlassianNavigation, LazyOpsReportsUnsubscribe],
});

export const eocReportsTeamRouteEntry = createEntry(eocReportsTeamRoute, {
	component: OpsReports,
	layout: opsTeamLayout,

	resources: [
		...getNavigationResources(),
		...teamDashboardResources,
		...opsReportsResources,
		getConsolidationStateResource(),
	],

	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		onlyShowHorziontalOnNav4: true,
	},

	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyOpsReports,
		LazyTeamDashboardSidebar,
	],
});

export const eocOpsRouteEntries = [
	eocReportsTeamRedirectRouteEntry,
	eocReportsUnsubscribeEntry,
	eocGlobalReportsRouteNav4Entry,
	eocGlobalReportsNav4Entry,
	eocReportsTeamRouteEntry,
];
