import type { Extension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';

export { overrideExtension } from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension';

export const isExtensionVisible = (extension: Extension) => !extension.hiddenBy;

export const isExtensionHidden = (extension: Extension) => !!extension.hiddenBy;

export const isExtensionHiddenByAppAccessRules = (extension: Extension) =>
	extension.hiddenBy === 'AppAccessRules';

export const isExtensionHiddenByDisplayConditions = (extension: Extension) =>
	extension.hiddenBy === 'DisplayConditions';
