import uniqBy from 'lodash/uniqBy';
import type {
	FullPage,
	AdminPage,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { parseExtensionId } from '@atlassian/jira-forge-ui-utils/src/utils/parse-extension-id/index.tsx';

export const uniqueExtensionsFilter = <T extends FullPage>(extensions: T[]): T[] =>
	uniqBy(extensions, (extension) => {
		const { appId, envId } = parseExtensionId(extension.id);
		return `${appId}_${envId}`;
	});

export const adminPageFilter = (extensions: AdminPage[]): AdminPage[] => {
	const configPageFilter = (extension: AdminPage) => extension.properties.useAsConfig;
	const getStartedPageFilter = (extension: AdminPage) => extension.properties.useAsGetStarted;
	const mainPageFilter = (extension: AdminPage) =>
		!configPageFilter(extension) && !getStartedPageFilter(extension);

	return [
		...uniqueExtensionsFilter(extensions.filter(mainPageFilter)),
		...uniqueExtensionsFilter(extensions.filter(configPageFilter)),
		...uniqueExtensionsFilter(extensions.filter(getStartedPageFilter)),
	];
};
