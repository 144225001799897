import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import type { JSMOperationsHorizontalNav } from './ui';

export const LazyJSMOperationsHorizontalNav = lazyForPaint<typeof JSMOperationsHorizontalNav>(() =>
	import(/* webpackChunkName: "jira-operations-horizontal-nav" */ './ui').then(
		({ JSMOperationsHorizontalNav }) => JSMOperationsHorizontalNav,
	),
);

export const AsyncJSMOperationsHorizontalNav = () => (
	<Placeholder name="horizontal-operations-nav" fallback={null}>
		<JSErrorBoundary
			id="asyncHorizontalOperationsNav"
			packageName="jiraHorizontalNavJsmOperations"
			fallback="flag"
			teamName="alexstrasza"
		>
			<LazyJSMOperationsHorizontalNav />
		</JSErrorBoundary>
	</Placeholder>
);
