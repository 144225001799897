import React, { useState } from 'react';

import {
	SetSideNavDesktopVisibility,
	SetSideNavMobileVisibility,
	SideNavDesktopVisibility,
	SideNavMobileVisibility,
} from './context';

/**
 * Manages the side nav visibility state and provides the context.
 */
export const SideNavVisibilityProvider = ({ children }: { children: React.ReactNode }) => {
	const [visibleOnDesktop, setVisibleOnDesktop] = useState<boolean | null>(null);
	const [visibleOnMobile, setVisibleOnMobile] = useState(false);

	return (
		<SideNavDesktopVisibility.Provider value={visibleOnDesktop}>
			<SetSideNavDesktopVisibility.Provider value={setVisibleOnDesktop}>
				<SideNavMobileVisibility.Provider value={visibleOnMobile}>
					<SetSideNavMobileVisibility.Provider value={setVisibleOnMobile}>
						{children}
					</SetSideNavMobileVisibility.Provider>
				</SideNavMobileVisibility.Provider>
			</SetSideNavDesktopVisibility.Provider>
		</SideNavDesktopVisibility.Provider>
	);
};
