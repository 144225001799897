import React, { type ReactNode, type ComponentType } from 'react';
import { lazy } from 'react-loosely-lazy';
import { ReportErrors } from '@atlassian/jira-errors-handling';
import Placeholder from '@atlassian/jira-placeholder';
import ErrorBoundary from './main';
import type { ErrorPageProps } from './ui/error-page';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ErrorPageLazy: ComponentType<ErrorPageProps> = lazy(() =>
	import(
		/* webpackMode: "eager" */ /* webpackChunkName: "sync-error-page" */ './ui/error-page'
	).then((module) => module.ErrorPage),
);

/**
 * @deprecated Do not use directly, instead use `JSErrorBoundary` in
 * `@atlassian/jira-error-boundaries`, which will log appropriate errors to
 * Sentry/SFX/Splunk. See https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/jira/docs/playbook/uncaught-error-handling.md
 */
export const ErrorPage = (props: ErrorPageProps) => {
	const packageDetails = {
		id: 'asyncErrorPageComponent',
		packageName: 'jiraErrorBoundary',
	};

	return (
		<ErrorBoundary {...packageDetails}>
			<ReportErrors teamName="jfp-magma" {...packageDetails}>
				<Placeholder name="error-boundary" fallback={null}>
					<ErrorPageLazy {...props} />
				</Placeholder>
			</ReportErrors>
		</ErrorBoundary>
	);
};

export type ExtraEventData = {
	[attributeKey: string]: string | boolean | number;
};
export type Props = {
	children: ReactNode;
	extraEventData?: ExtraEventData;
	withExperienceTracker?: boolean;
	id?: string;
	packageName?: string;
	teamName?: string;
};

const renderErrorPage = ({ error }: { error: Error }) => <ErrorPage error={error} />;

/**
 * An error boundary that will render an atlaskit empty state when a render
 *  exception is thrown.
 *
 * @deprecated Do not use directly, instead use `JSErrorBoundary` in
 * `@atlassian/jira-error-boundaries`, which will log appropriate errors to
 * Sentry/SFX/Splunk. See https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/jira/docs/playbook/uncaught-error-handling.md
 */
const ErrorBoundaryPage = ({ children, ...props }: Props) => (
	<ErrorBoundary {...props} render={renderErrorPage}>
		{children}
	</ErrorBoundary>
);

ErrorBoundaryPage.defaultProps = {
	extraEventData: {},
	id: undefined,
};

export { ErrorBoundaryPage };
export { default as ErrorBoundary, ErrorBoundaryInner } from './main';
export type { Props as ErrorBoundaryProps, ErrorBoundaryFallbackComponent } from './main';
export type { CaughtError, ExtraErrorAttributes } from './main';
