/**
 * @generated SignedSource<<1e7fc54e25b068be84af7032c7c5ed3b>>
 * @relayHash b7b7b5d974b762d35ecbb159c92ab551
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a8123f73d1d8d57464a0715023eab5fde9d409c450171c8d88036deef0131a84

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiVersionDetailsQuery } from './uiVersionDetailsQuery.graphql';

const node: PreloadableConcreteRequest<uiVersionDetailsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a8123f73d1d8d57464a0715023eab5fde9d409c450171c8d88036deef0131a84",
    "metadata": {},
    "name": "uiVersionDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
