/**
 * @generated SignedSource<<073d38914b959679bd76524c19d9b8c4>>
 * @relayHash ecac5ce93a1a8f7ce3bc1278d240541d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 430ff476ed4399665982cd74c48c9f179b88585083efb079b00fb058290b6934

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraContainerNavigationQueryInput = {
  projectKeyQuery?: JiraContainerNavigationByProjectKeyQueryInput | null | undefined;
  scopeId?: string | null | undefined;
};
export type JiraContainerNavigationByProjectKeyQueryInput = {
  cloudId: string;
  projectKey: string;
};
export type uiHorizontalSoftwareProjectNavQuery$variables = {
  boardId?: string | null | undefined;
  cloudId: string;
  containerNavigationInput: JiraContainerNavigationQueryInput;
  first: number;
  projectAri: string;
  projectKey: string;
  useContainerNavigation: boolean;
  useProjectAri: boolean;
  useProjectKey: boolean;
};
export type uiHorizontalSoftwareProjectNavQuery$data = {
  readonly jira: {
    readonly containerNavigation?: {
      readonly canEdit?: boolean | null | undefined;
      readonly navigationItems?: {
        readonly " $fragmentSpreads": FragmentRefs<"ui_navigationKitTabList_TabList_tabsFragment">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ui_navigationKitAddTab_AddTab" | "ui_navigationKitTabList_TabList_containerFragment">;
    } | null | undefined;
    readonly jiraSoftwareNavigationItems?: {
      readonly " $fragmentSpreads": FragmentRefs<"ui_navigationKitTabList_TabList_tabsFragment">;
    };
    readonly projectDataById?: {
      readonly " $fragmentSpreads": FragmentRefs<"projectBoardHeader_horizontalNavHeader" | "ui_navigationKitTabList_TabList_projectFragment">;
    } | null | undefined;
    readonly projectDataByKey?: {
      readonly " $fragmentSpreads": FragmentRefs<"projectBoardHeader_horizontalNavHeader" | "ui_navigationKitTabList_TabList_projectFragment">;
    } | null | undefined;
  };
};
export type uiHorizontalSoftwareProjectNavQuery = {
  response: uiHorizontalSoftwareProjectNavQuery$data;
  variables: uiHorizontalSoftwareProjectNavQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "containerNavigationInput"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectAri"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useContainerNavigation"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useProjectAri"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useProjectKey"
},
v9 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v10 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v11 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  (v9/*: any*/),
  (v10/*: any*/),
  {
    "kind": "Variable",
    "name": "projectIdOrKey",
    "variableName": "projectKey"
  }
],
v12 = [
  {
    "kind": "FragmentSpread",
    "name": "ui_navigationKitTabList_TabList_tabsFragment"
  }
],
v13 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "containerNavigationInput"
  }
],
v14 = {
  "kind": "ScalarField",
  "name": "canEdit"
},
v15 = [
  (v10/*: any*/)
],
v16 = [
  (v9/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v17 = [
  {
    "kind": "FragmentSpread",
    "name": "projectBoardHeader_horizontalNavHeader"
  },
  {
    "kind": "FragmentSpread",
    "name": "ui_navigationKitTabList_TabList_projectFragment"
  }
],
v18 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v19 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v20 = {
  "kind": "ScalarField",
  "name": "id"
},
v21 = {
  "kind": "ScalarField",
  "name": "label"
},
v22 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v23 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v24 = {
  "kind": "ScalarField",
  "name": "url"
},
v25 = {
  "kind": "ScalarField",
  "name": "styleClass"
},
v26 = [
  {
    "concreteType": "JiraNavigationItemEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v19/*: any*/),
          (v20/*: any*/),
          {
            "kind": "ScalarField",
            "name": "isDefault"
          },
          {
            "kind": "ScalarField",
            "name": "typeKey"
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isJiraNavigationItem"
          },
          (v21/*: any*/),
          {
            "kind": "ScalarField",
            "name": "canRemove"
          },
          {
            "kind": "ScalarField",
            "name": "canSetAsDefault"
          },
          (v22/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "JiraAppSection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": true,
                "selections": [
                  (v21/*: any*/),
                  {
                    "concreteType": "JiraAppNavigationItemNestedLink",
                    "kind": "LinkedField",
                    "name": "links",
                    "plural": true,
                    "selections": [
                      (v21/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/)
                    ]
                  }
                ]
              },
              (v24/*: any*/),
              (v23/*: any*/),
              (v25/*: any*/),
              {
                "kind": "ScalarField",
                "name": "appType"
              },
              {
                "kind": "ScalarField",
                "name": "envLabel"
              }
            ],
            "type": "JiraAppNavigationItem"
          }
        ]
      }
    ]
  }
],
v27 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v28 = [
  (v20/*: any*/),
  {
    "kind": "ScalarField",
    "name": "projectId"
  },
  {
    "alias": "projectKey",
    "kind": "ScalarField",
    "name": "key"
  },
  {
    "kind": "ScalarField",
    "name": "isFavourite"
  },
  {
    "kind": "ScalarField",
    "name": "projectStyle"
  },
  {
    "kind": "ScalarField",
    "name": "projectType"
  },
  {
    "kind": "ScalarField",
    "name": "projectTypeName"
  },
  {
    "kind": "ScalarField",
    "name": "webUrl"
  },
  {
    "kind": "ScalarField",
    "name": "status"
  },
  {
    "alias": "canEditProjectConfig",
    "args": [
      {
        "kind": "Literal",
        "name": "type",
        "value": "EDIT_PROJECT_CONFIG"
      }
    ],
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v27/*: any*/),
    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
  },
  {
    "alias": "canViewProjectConfig",
    "args": [
      {
        "kind": "Literal",
        "name": "type",
        "value": "VIEW_PROJECT_CONFIG"
      }
    ],
    "concreteType": "JiraProjectAction",
    "kind": "LinkedField",
    "name": "action",
    "plural": false,
    "selections": (v27/*: any*/),
    "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
  },
  (v22/*: any*/),
  {
    "kind": "ScalarField",
    "name": "softwareBoardCount"
  },
  {
    "kind": "LinkedField",
    "name": "navigationMetadata",
    "plural": false,
    "selections": [
      (v19/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "kind": "ScalarField",
            "name": "boardId"
          },
          {
            "kind": "ScalarField",
            "name": "isSimpleBoard"
          },
          (v20/*: any*/)
        ],
        "type": "JiraSoftwareProjectNavigationMetadata"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "condition": "useContainerNavigation",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "args": (v11/*: any*/),
                    "concreteType": "JiraNavigationItemConnection",
                    "kind": "LinkedField",
                    "name": "jiraSoftwareNavigationItems",
                    "plural": false,
                    "selections": (v12/*: any*/)
                  },
                  "action": "THROW",
                  "path": "jira.jiraSoftwareNavigationItems"
                }
              ]
            },
            {
              "condition": "useContainerNavigation",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "args": (v13/*: any*/),
                  "kind": "LinkedField",
                  "name": "containerNavigation",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_navigationKitTabList_TabList_containerFragment"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_navigationKitAddTab_AddTab"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v14/*: any*/),
                        {
                          "args": (v15/*: any*/),
                          "concreteType": "JiraNavigationItemConnection",
                          "kind": "LinkedField",
                          "name": "navigationItems",
                          "plural": false,
                          "selections": (v12/*: any*/)
                        }
                      ],
                      "type": "JiraContainerNavigation"
                    }
                  ]
                }
              ]
            },
            {
              "condition": "useProjectKey",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": "projectDataByKey",
                  "args": (v16/*: any*/),
                  "concreteType": "JiraProject",
                  "kind": "LinkedField",
                  "name": "jiraProjectByKey",
                  "plural": false,
                  "selections": (v17/*: any*/)
                }
              ]
            },
            {
              "condition": "useProjectKey",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "condition": "useProjectAri",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": "projectDataById",
                      "args": (v18/*: any*/),
                      "concreteType": "JiraProject",
                      "kind": "LinkedField",
                      "name": "jiraProject",
                      "plural": false,
                      "selections": (v17/*: any*/)
                    }
                  ]
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v11/*: any*/),
                "concreteType": "JiraNavigationItemConnection",
                "kind": "LinkedField",
                "name": "jiraSoftwareNavigationItems",
                "plural": false,
                "selections": (v26/*: any*/)
              }
            ]
          },
          {
            "condition": "useContainerNavigation",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": (v13/*: any*/),
                "kind": "LinkedField",
                "name": "containerNavigation",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isJiraContainerNavigationResult"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v20/*: any*/),
                      (v14/*: any*/),
                      {
                        "args": (v15/*: any*/),
                        "concreteType": "JiraNavigationItemConnection",
                        "kind": "LinkedField",
                        "name": "navigationItems",
                        "plural": false,
                        "selections": (v26/*: any*/)
                      }
                    ],
                    "type": "JiraContainerNavigation"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v20/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              }
            ]
          },
          {
            "condition": "useProjectKey",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "projectDataByKey",
                "args": (v16/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": (v28/*: any*/)
              }
            ]
          },
          {
            "condition": "useProjectKey",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "condition": "useProjectAri",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "projectDataById",
                    "args": (v18/*: any*/),
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "jiraProject",
                    "plural": false,
                    "selections": (v28/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "430ff476ed4399665982cd74c48c9f179b88585083efb079b00fb058290b6934",
    "metadata": {},
    "name": "uiHorizontalSoftwareProjectNavQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "bf77e8c96ff302d165517a93e4b8b372";

export default node;
