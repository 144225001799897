import type { ComponentType } from 'react';
import { AsyncHorizontalProjectNav as AsyncHorizontalNavCore } from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ROUTE_GROUPS_CLASSIC_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIsChromeless } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/chromeless/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout/src/common/utils/get-will-show-nav4/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { useRouter } from '@atlassian/jira-router';
import { BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import {
	GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
	GENERIC_PROJECT_HORIZONTAL_NAV,
} from '../../constants';

/** "header" component above the page's content
 * It contains:
 * - Heading
 * - Breadcrumb
 * - misc buttons such as share, feedback, etc.
 * - tab navigation (if nav v4)
 */
export const useHorizontalNavComponent = (): ComponentType | null => {
	const [{ route, query }] = useRouter();
	const { data: projectData } = useProjectContext();

	// chromeless mode is handled in page-container package with nav4
	if (!getWillShowNav4()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [isChromeless] = useIsChromeless();
		if (isChromeless) {
			return null;
		}
	}

	if (route.navigation?.horizontal == null) {
		return null;
	}

	// Shared horizontal navigation routes that currently live in business projects on prod
	// but we want to remove horizontal navigation on with the navigation redesign
	if (route.navigation.horizontal === GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV) {
		if (getWillShowNav4()) {
			return null;
		}
		switch (projectData?.projectType) {
			case CORE_PROJECT:
				return AsyncHorizontalNavCore;
			default:
				return null;
		}
	}

	// Board settings pages should not show the horizontal nav.
	// This is a workaround due to the fact that the software CMP board/backlog pages share the same route name
	// as the board settings pages. This code should be removed once the board settings pages have
	// their own dedicated route names.
	if (
		route.group === ROUTE_GROUPS_CLASSIC_SOFTWARE &&
		BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES.has(query.config) &&
		getWillShowNav4() &&
		ff('simplified-boards-milestone-2_07hbk')
	) {
		return null;
	}

	if (route.navigation.horizontal === GENERIC_PROJECT_HORIZONTAL_NAV) {
		switch (projectData?.projectType) {
			case CORE_PROJECT:
				return AsyncHorizontalNavCore;
			case SOFTWARE_PROJECT:
				if (getWillShowNav4()) {
					return AsyncHorizontalSoftwareProjectNav;
				}
				return null;
			default:
				return null;
		}
	}

	// TODO: Remove this during jira_nav4 cleanup
	if ('onlyShowHorziontalOnNav4' in route.navigation && route.navigation.onlyShowHorziontalOnNav4) {
		return getWillShowNav4() ? route.navigation.horizontal : null;
	}

	if ('horizontalFeatureFlag' in route.navigation) {
		switch (route.navigation.horizontalFeatureFlag) {
			// Society: "Use a constant!"
			// Me: no! I was told to inline my feature flags!
			case 'jsm-views-of-work-phase-1_6wljy':
				return ff('jsm-views-of-work-phase-1_6wljy') ? route.navigation.horizontal : null;
			default: {
				// a little bit of magic to ensure that we handle all feature flags defined in
				// HorizontalFeatureFlags - if the switch statement is missing a case, this line
				// will cause a compile error.
				const exhaustiveCheck: never = route.navigation.horizontalFeatureFlag;
				return exhaustiveCheck;
			}
		}
	}

	return route.navigation.horizontal;
};
