import isNil from 'lodash/isNil';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { type RouterDataContext, createResource, type RouterContext } from '@atlassian/jira-router';
import {
	RESOURCE_TYPE_ADVANCED_ROADMAPS_PLAN_INITIAL_CHECKS,
	RESOURCE_TYPE_ADVANCED_ROADMAPS_REPORT_INITIAL_CHECKS,
	RESOURCE_TYPE_ADVANCED_ROADMAPS_SCENARIO_ID,
	RESOURCE_TYPE_ADVANCED_ROADMAPS_LAST_VISITED_PROGRAM_BOARD_ID,
} from '../common/constants';
import type { PlanPageInitialChecksResponse, ReportPageInitialChecksResponse } from './types';

export const advancedRoadmapsReduxStoreStateResource = createResource({
	type: 'ADVANCED_ROADMAPS_REDUX_STORE_STATE',
	getKey: ({ match }: RouterContext) => {
		const { params, query } = match;

		if (params.planId != null && params.scenarioId != null) {
			return `${params.planId}:${params.scenarioId}`;
		}

		if (params.reportId != null) {
			return params.reportId;
		}

		if (query.r != null) {
			return query.r;
		}

		throw new Error('Unable to get resource key for ADVANCED_ROADMAPS_REDUX_STORE_STATE.');
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getData: async (): Promise<any> => undefined,
	isBrowserOnly: true,
	// Invalidation is implemented here: src/packages/platform/ui/layouts/jira-plan-layout/src/ui/resources-invalidate/index.tsx
	maxAge: Infinity,
});

export const advancedRoadmapsScenarioIdResource = createResource<{
	scenarioId: number;
}>({
	type: RESOURCE_TYPE_ADVANCED_ROADMAPS_SCENARIO_ID,
	getKey: ({ query }: RouterContext) => `scenarioId_${query.id}`,
	getData: async ({ query }: RouterDataContext) => {
		const planId = query.id;
		const { id } = await performGetRequest(`/rest/jpo/1.0/scenarios/${planId}`);

		return { scenarioId: id };
	},
	maxAge: 0,
});

export const advancedRoadmapsPlanPageInitialChecksResource =
	createResource<PlanPageInitialChecksResponse>({
		type: RESOURCE_TYPE_ADVANCED_ROADMAPS_PLAN_INITIAL_CHECKS,
		getKey: ({ match }: RouterContext) => {
			const { planId, scenarioId } = match.params;

			return `planPageInitialChecks_${planId != null ? planId : ''}_${
				scenarioId != null ? scenarioId : ''
			}`;
		},
		getData: async ({ match }: RouterDataContext) => {
			const { planId, scenarioId } = match.params;

			// @ts-expect-error - TS2367 - This condition will always return 'false' since the types '"null"' and '"undefined"' have no overlap.
			if (planId == null || (planId === 'null' && planId === 'undefined' && planId === '')) {
				throw new Error('Plan cannot be loaded without a planId');
			}

			let url = `/rest/jpo/1.0/plans/${planId}/checks`;
			if (
				scenarioId != null &&
				scenarioId !== 'null' &&
				scenarioId !== 'undefined' &&
				scenarioId !== ''
			) {
				url = `${url}?sid=${scenarioId}`;
			}

			const response = await performGetRequest(url);

			return response;
		},
		// Invalidation is implemented here: src/packages/platform/ui/layouts/jira-plan-layout/src/ui/resources-invalidate/index.tsx
		maxAge: Infinity,
	});

export const advancedRoadmapsPlanReportPageInitialChecksResource =
	createResource<ReportPageInitialChecksResponse>({
		type: RESOURCE_TYPE_ADVANCED_ROADMAPS_REPORT_INITIAL_CHECKS,
		getKey: ({ match }: RouterContext) =>
			`planReportPageInitialChecks_${match.params.reportId == null ? '' : match.params.reportId}`,
		getData: async ({ match }: RouterDataContext) => {
			const initialPlanChecksPromise = window.__ARJ_INITIAL_PLAN_REPORT_CHECKS_PROMISE__;

			delete window.__ARJ_INITIAL_PLAN_REPORT_CHECKS_PROMISE__;

			if (initialPlanChecksPromise) {
				const response = await initialPlanChecksPromise;
				return response;
			}

			const { reportId } = match.params;

			const response = await performGetRequest(
				`/rest/jpo/1.0/reportinfo/generic/${reportId == null ? '' : reportId}/checks`,
			);

			return response;
		},
		// Invalidation is implemented here: src/packages/platform/ui/layouts/jira-plan-layout/src/ui/resources-invalidate/index.tsx
		maxAge: Infinity,
	});

export const advancedRoadmapsLastVisitedProgramBoardIdResource = createResource<{
	boardId: string | null;
}>({
	type: RESOURCE_TYPE_ADVANCED_ROADMAPS_LAST_VISITED_PROGRAM_BOARD_ID,
	getKey: ({ query }: RouterContext) => `lastVisitedProgramBoardId_${query.id}`,
	getData: async ({ match }: RouterDataContext) => {
		// Sometimes currentPlanId isn't yet ready - we retry a few times to check if it has loaded in before making the request
		const RETRIES = 3;
		const DELAY = 500;
		const attemptFetch = async (remainingRetries: number): Promise<{ boardId: string | null }> => {
			const currentPlanId = match.params.planId;
			if (!isNil(currentPlanId)) {
				const defaultBoardIdResponse = await performGetRequest(
					`/rest/jpo/2.0/plans/${currentPlanId}/increments/default`,
				);
				if (defaultBoardIdResponse === null) {
					return { boardId: null };
				}
				const { id } = defaultBoardIdResponse;
				return { boardId: id };
			}
			if (remainingRetries > 0) {
				await new Promise((resolve) => setTimeout(resolve, DELAY));
				return attemptFetch(remainingRetries - 1);
			}
			throw new Error(
				'Attempted to get the last visited program board of a plan without a plan id after multiple retries.',
			);
		};

		return attemptFetch(RETRIES);
	},
	// Invalidation is implemented here: src/packages/platform/ui/layouts/jira-plan-layout/src/ui/resources-invalidate/index.tsx
	maxAge: Infinity,
});
