/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useCallback, useContext } from 'react';

import { jsx } from '@compiled/react';

import { type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import { cssMap } from '@atlaskit/css';
import __noop from '@atlaskit/ds-lib/noop';
import SidebarCollapseIcon from '@atlaskit/icon/core/sidebar-collapse';
import { token } from '@atlaskit/tokens';

import { InteractionConsumer } from '../../../components/interaction-surface';

import { SetSideNavDesktopVisibility, SideNavDesktopVisibility } from './context';

const styles = cssMap({
	hiddenMobileAndDesktop: {
		display: 'none',
	},
	hiddenMobileOnly: {
		display: 'none',
		'@media (min-width: 64rem)': {
			display: 'initial',
		},
	},
	/**
	 * Used to stick the button to the top of the side nav, even while scrolling.
	 */
	stickyContainer: {
		position: 'sticky',
		insetBlockStart: token('space.0'),
		// Ensure it is above side nav content
		zIndex: 100,
	},
	absolutelyPositionInStickyContainer: {
		position: 'absolute',
		// Positioning at the top-right corner of the side nav
		insetInlineEnd: token('space.150'),
		insetBlockStart: token('space.150'),
	},
	buttonBackgroundColor: {
		backgroundColor: token('elevation.surface'),
	},
});

/**
 * __SideNavCollapseButton__
 *
 * The button for collapsing the side nav, for use within the `<SideNav>`.
 * Should be used within the `collapseButton` slot prop of the `SideNav` component, e.g.
 * ```tsx
 * <SideNav collapseButton={<SideNavCollapseButton label="Collapse sidebar" />} />
 * ```
 *
 * The alternative is `SideNavToggleButton`, which is for use within the top bar.
 *
 * It is only shown on desktop screens, when the side nav is visible.
 */
export const SideNavCollapseButton = ({
	testId,
	interactionName,
	onClick,
}: {
	testId?: string /**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */;
	interactionName?: string;
	onClick?: (e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
}) => {
	const isSideNavVisibleOnDesktop = useContext(SideNavDesktopVisibility);
	const setSideNavVisibleOnDesktop = useContext(SetSideNavDesktopVisibility);

	const collapseSideNav = useCallback(() => {
		setSideNavVisibleOnDesktop(false);
	}, [setSideNavVisibleOnDesktop]);

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>, analyticsEvent: UIAnalyticsEvent) => {
			onClick?.(event, analyticsEvent);

			collapseSideNav();
		},
		[onClick, collapseSideNav],
	);

	return (
		/**
		 * We need this extra sticky element because we want to use both sticky and absolute positioning together.
		 *
		 * The sticky container allows the button to move with the scroll position,
		 * and also creates a positioning context for the absolute positioning that is inside the scroll container.
		 *
		 * The absolute container allows us to take the button out of the flow
		 * and position it relative to the scroll container.
		 *
		 * This means the button will both:
		 *
		 * - move with the scroll position
		 * - respect the presence of a scrollbar (i.e. not overlap it)
		 */
		<div css={styles.stickyContainer}>
			<div
				css={[
					styles.absolutelyPositionInStickyContainer,
					isSideNavVisibleOnDesktop && styles.hiddenMobileOnly,
					!isSideNavVisibleOnDesktop && styles.hiddenMobileAndDesktop,
				]}
				data-testid={testId ? `${testId}-container` : undefined}
			>
				{/**
				 * Nesting in `InteractionConsumer` separately to the above `div` to prevent Compiled styling conflicts with
				 * `display` property.
				 */}
				<InteractionConsumer
					// Setting background color to the `InteractionConsumer` element so it's not visible when the button is hidden
					xcss={styles.buttonBackgroundColor}
					behavior="hideOnLeave"
				>
					<IconButton
						appearance="subtle"
						label="Collapse sidebar"
						icon={SidebarCollapseIcon}
						onClick={handleClick}
						testId={testId}
						isTooltipDisabled={false}
						interactionName={interactionName}
					/>
				</InteractionConsumer>
			</div>
		</div>
	);
};
