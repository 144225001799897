import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { ServiceDeskAnalyticsContext } from '@atlassian/jira-servicedesk-analytics/src/ui/servicedesk-analytics-context/index.tsx';
import type { ProjectBuilderProps, ProjectBuilder as ProjectBuilderDrawer } from './ui';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyComponent = lazy<typeof ProjectBuilderDrawer>(
	() => import(/* webpackChunkName: "async-project-builder" */ './index'),
	{ ssr: false },
);

export const AsyncProjectBuilderDrawer = (props: Partial<ProjectBuilderProps>) => (
	<JSErrorBoundary
		id="bundle-async-project-builder"
		packageName="jira-servicedesk-project-builder"
		fallback="flag"
	>
		<Placeholder fallback={null} name="jsm-project-builder">
			<ServiceDeskAnalyticsContext>
				<LazyComponent isOpenInitial={!!props.isOpenInitial} {...props} />
			</ServiceDeskAnalyticsContext>
		</Placeholder>
	</JSErrorBoundary>
);

export const canUseProjectBuilder = (isAdmin: boolean): boolean => isAdmin;
