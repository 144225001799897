import type { MessageDescriptor } from '@atlassian/jira-intl';
import { getTypedKeys } from '@atlassian/jira-shared-types/src/narrowing.tsx';
import messages from './messages';

/**
 * Work categories x API resource
 * all the values returned by API
 */
export const CHANGES = 'change-management' as const;
export const INCIDENTS = 'incident-management' as const;
export const PROBLEMS = 'problem-management' as const;
export const SERVICE_REQUEST = 'service-request' as const;
export const POST_INCIDENT_REVIEWS = 'post-incident-review' as const;
export const DEVELOPER_ESCALATION = 'developer-escalation' as const;

/**
 * Work categories x UI extra
 * extra entry to ensure fallback scenario
 */
export const NO_CATEGORY = 'no-category' as const;
export const NO_CATEGORY_NEW = 'no-category-new' as const;
export const NO_PRACTICE = 'no-practice' as const; // BERRY-1358 should consolidate into NO_CATEGORY

/**
 * Work category x Short form
 * usually used in urls
 */
export const SHORT_FORM_CHANGES = 'changes' as const;
export const SHORT_FORM_INCIDENTS = 'incidents' as const;
export const SHORT_FORM_PROBLEMS = 'problems' as const;
export const SHORT_FORM_SERVICE_REQUEST = 'service-requests' as const;
export const SHORT_FORM_POST_INCIDENT_REVIEWS = 'post-incident-reviews' as const;
export const SHORT_FORM_DEVELOPER_ESCALATION = 'developer-escalations' as const;

export type ItsmPracticeCategory =
	| typeof CHANGES
	| typeof INCIDENTS
	| typeof PROBLEMS
	| typeof SERVICE_REQUEST
	| typeof POST_INCIDENT_REVIEWS;

export type ItsmPractice = ItsmPracticeCategory | typeof NO_CATEGORY;

export const WORK_CATEGORIES_API_RESOURCE = [
	CHANGES,
	INCIDENTS,
	PROBLEMS,
	SERVICE_REQUEST,
	POST_INCIDENT_REVIEWS,
	DEVELOPER_ESCALATION,
]; // /!\ defines WorkCategoryApiResource type

/**
 * Work categories x Queue
 * NOTE: have these in order they should appear in UI (used at src/packages/router-resources/service-desk-practices/src/controllers/index.tsx)
 */
export const WORK_CATEGORIES_QUEUE_SUPPORTED: ItsmPracticeCategory[] = [
	SERVICE_REQUEST,
	INCIDENTS,
	PROBLEMS,
	CHANGES,
	POST_INCIDENT_REVIEWS,
]; // /!\ defines WorkCategoryQueueSupported type

export const ALL_PRACTICES: ItsmPractice[] = [...WORK_CATEGORIES_QUEUE_SUPPORTED, NO_CATEGORY];

export const WORK_CATEGORIES_SHORT_FORM = [
	SHORT_FORM_CHANGES,
	SHORT_FORM_INCIDENTS,
	SHORT_FORM_PROBLEMS,
	SHORT_FORM_SERVICE_REQUEST,
	SHORT_FORM_POST_INCIDENT_REVIEWS,
	SHORT_FORM_DEVELOPER_ESCALATION,
] as const; // /!\ defines WorkCategoryShortForm type

/**
 * Work category x messages
 */
export const PRACTICE_LABEL_MAP = {
	[CHANGES]: messages.changes,
	[SERVICE_REQUEST]: messages.serviceRequests,
	[PROBLEMS]: messages.problems,
	[INCIDENTS]: messages.incidents,
	[POST_INCIDENT_REVIEWS]: messages.postIncidentReviews,
	[NO_CATEGORY]: messages.queues,
	[DEVELOPER_ESCALATION]: messages.developerEscalation,
	[NO_PRACTICE]: messages.noCategory,
} as const;

export const PRACTICE_LABEL_MAP_LOWER_CASE = {
	[CHANGES]: messages.changesLower,
	[SERVICE_REQUEST]: messages.serviceRequestsLower,
	[PROBLEMS]: messages.problemsLower,
	[INCIDENTS]: messages.incidentsLower,
	[POST_INCIDENT_REVIEWS]: messages.postIncidentReviewsLower,
	[NO_CATEGORY]: messages.queuesLower,
	[DEVELOPER_ESCALATION]: messages.developerEscalationLower,
	[NO_PRACTICE]: messages.noCategoryLower,
} as const;

type WorkCategoryQueueSupportedLabelsType = Record<ItsmPracticeCategory, MessageDescriptor>;

export const WORK_CATEGORIES_QUEUE_SUPPORTED_LABELS: WorkCategoryQueueSupportedLabelsType =
	getTypedKeys(PRACTICE_LABEL_MAP).reduce((acc, labelMapKey) => {
		// cannot type .indexOf() as not-string in Ts, therefore need to use .find(). And shows error if `key` in acc[key] being of wider type than the result (than ItsmPracticeCategory), therefore, need to use different keys in the `acc[queueKey] =` line
		const queueKey = WORK_CATEGORIES_QUEUE_SUPPORTED.find((key) => key === labelMapKey);
		if (queueKey) {
			acc[queueKey] = PRACTICE_LABEL_MAP[labelMapKey];
		}
		return acc;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	}, {} as WorkCategoryQueueSupportedLabelsType);

/**
 * Work category x JQL
 */
export const CHANGES_JQL_NAME = 'Changes' as const;
export const INCIDENTS_JQL_NAME = 'Incidents' as const;
export const PROBLEMS_JQL_NAME = 'Problems' as const;
export const SERVICE_REQUEST_JQL_NAME = 'Service requests' as const;
export const POST_INCIDENT_REVIEWS_JQL_NAME = 'Post-incident reviews' as const;
export const DEVELOPER_ESCALATION_JQL_NAME = 'Developer escalation' as const;

export const practiceKeyJqlNameMapping = {
	[CHANGES]: CHANGES_JQL_NAME,
	[INCIDENTS]: INCIDENTS_JQL_NAME,
	[PROBLEMS]: PROBLEMS_JQL_NAME,
	[SERVICE_REQUEST]: SERVICE_REQUEST_JQL_NAME,
	[POST_INCIDENT_REVIEWS]: POST_INCIDENT_REVIEWS_JQL_NAME,
	[DEVELOPER_ESCALATION]: DEVELOPER_ESCALATION_JQL_NAME,
} as const;
