import type {
	CalendarIssueEventFields,
	CalendarScopeProject,
	CalendarViewMode,
	CalendarWeekStart,
} from '@atlassian/jira-calendar/src/controllers/calendar-store/types.tsx';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import parameters from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery$parameters';
import type {
	JiraViewScopeInput,
	JiraCalendarViewConfigurationInput,
	JiraCalendarIssuesInput,
	JiraCalendarMode,
	JiraCalendarWeekStart,
} from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

function toMidnightUTCString(date: Date) {
	const utcMilliseconds = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
	return new Date(utcMilliseconds).toISOString();
}

function toDate(date: string | null | undefined): Date {
	if (!date) {
		return new Date();
	}
	try {
		const _date = new Date(date);
		return new Date(_date.getFullYear(), _date.getMonth(), _date.getDate());
	} catch (e) {
		return new Date();
	}
}

function createScope({ context }: EntryPointRouteParams): CalendarScopeProject {
	return {
		type: 'project',
		projectKeys: [String(context.match.params.projectKey)],
	};
}

function buildScopeInput(
	{ tenantContext }: EntryPointRouteParams,
	scope: CalendarScopeProject,
): JiraViewScopeInput {
	const { cloudId } = tenantContext;
	return {
		projectKeys: {
			cloudId,
			keys: scope.projectKeys,
		},
	};
}

function toViewMode(mode: string): JiraCalendarMode {
	switch (mode) {
		case 'week':
			return 'WEEK';
		case 'day':
			return 'DAY';
		default:
			return 'MONTH';
	}
}

function toWeekStart(weekStart: string): JiraCalendarWeekStart {
	switch (weekStart) {
		case 'monday':
			return 'MONDAY';
		case 'saturday':
			return 'SATURDAY';
		default:
			return 'SUNDAY';
	}
}

function buildConfigurationInput({
	selectedDate,
	viewMode,
	weekStartsOn,
	startDateField,
	endDateField,
}: {
	selectedDate: Date;
	viewMode: CalendarViewMode;
	weekStartsOn: CalendarWeekStart;
	startDateField: string;
	endDateField: string;
}): JiraCalendarViewConfigurationInput {
	return {
		date: toMidnightUTCString(selectedDate),
		mode: toViewMode(viewMode),
		weekStart: toWeekStart(weekStartsOn),
		startDateField,
		endDateField,
	};
}

function buildIssuesSearchInput(): JiraCalendarIssuesInput {
	return { additionalFilterQuery: '' };
}

function buildUnscheduledIssuesSearchInput(): JiraCalendarIssuesInput {
	return { additionalFilterQuery: '' };
}

export const calendarEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-business-calendar-new" */ './src'),
	),
	getPreloadProps: (entryPointRouteParams: EntryPointRouteParams) => {
		const { context, tenantContext } = entryPointRouteParams;

		const { cloudId } = tenantContext;
		const scope = createScope(entryPointRouteParams);

		const { date } = context.query;
		const selectedDate = toDate(date);

		const viewMode: CalendarViewMode = 'month';
		const weekStartsOn: CalendarWeekStart = 'sunday';
		const startDateField = 'startdate';
		const endDateField = 'duedate';
		const issueEventFields: CalendarIssueEventFields = {
			startDateField,
			endDateField,
		};

		return {
			queries: {
				calendarData: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters,
					variables: {
						cloudId,
						scopeInput: buildScopeInput(entryPointRouteParams, scope),
						configurationInput: buildConfigurationInput({
							selectedDate,
							viewMode,
							weekStartsOn,
							startDateField,
							endDateField,
						}),
						issuesSearchInput: buildIssuesSearchInput(),
						unscheduledIssuesSearchInput: buildUnscheduledIssuesSearchInput(),
						versionsSearchInput: null,
						sprintsSearchInput: null,
						skipSprintSearch: true,
						skipVersionSearch: true,
						viewId: null,
						queryV2Enabled: ff('update-calendar-query_n6opw'),
						filterProjectContextEnabled: fg('calendar_set_filter_context'),
					},
				},
			},
			entryPoints: {},
			extraProps: {
				scope,
				selectedDate,
				viewMode,
				weekStartsOn,
				issueEventFields,
			},
		};
	},
});
