import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import {
	AsyncHorizontalOperationsNav,
	LazyHorizontalOperationsNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { resources as alertPoliciesResources } from '@atlassian/jira-router-resources-eoc-policies';
import { resources as teamDashboardResources } from '@atlassian/jira-router-resources-eoc-team-dashboard';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	alertPoliciesRoute,
	globalPoliciesRoute,
} from '@atlassian/jira-router-routes-eoc-policies-routes';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LazyAlertPolicies = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-policies", jiraSpaEntry: "async-jira-eoc-policies" */ '@atlassian/jira-eoc-policies'
		),
);

const AlertPoliciesComponent = () => (
	<LazyPage
		Page={LazyAlertPolicies}
		pageId="async-jira-eoc-alert-policies"
		shouldShowSpinner={false}
	/>
);

export const alertPoliciesRouteEntry = createEntry(alertPoliciesRoute, {
	exact: true,
	component: AlertPoliciesComponent,
	layout: opsTeamLayout,
	resources: [
		...getNavigationResources(),
		...alertPoliciesResources,
		...teamDashboardResources,
		getConsolidationStateResource(),
	],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
		horizontal: AsyncHorizontalOperationsNav,
		onlyShowHorziontalOnNav4: true,
	},
	forPaint: [
		LazyHorizontalOperationsNav,
		LazyAtlassianNavigation,
		LazyAlertPolicies,
		LazyTeamDashboardSidebar,
	],
});

export const globalPoliciesRouteEntry = createEntry(globalPoliciesRoute, {
	exact: true,
	component: AlertPoliciesComponent,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...alertPoliciesResources,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorziontalOnNav4: true,
	},
	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyAlertPolicies],
});

export const eocPoliciesRouteEntries = [alertPoliciesRouteEntry, globalPoliciesRouteEntry];
