import {
	MENU_ID_APPS,
	MENU_ID_ASSETS,
	MENU_ID_DASHBOARDS,
	MENU_ID_FILTERS,
	MENU_ID_GOALS,
	MENU_ID_OPERATIONS,
	MENU_ID_OVERVIEWS,
	MENU_ID_PLANS,
	MENU_ID_PROJECTS,
	MENU_ID_RECENT,
	MENU_ID_STARRED,
	MENU_ID_TEAMS,
	type ConfigurableMenuId,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants';
import { NAVIGATION_ITEM } from './component-type';
import { V4 } from './nav-state';
import { TOP, SIDEBAR } from './navigation-container';
import { GLOBAL } from './navigation-layer';

const SIDEBAR_TOGGLE = 'sidebarToggle';
const SIDEBAR_TOGGLE_ACTION_COLLAPSE = 'collapse';
const SIDEBAR_TOGGLE_ACTION_EXPAND = 'expand';
const CLICKED = 'clicked';

/*
    - This payload is used when SideNavCollapseButton's onClick event is fired. 
    - Note that the navigationContainer is SIDEBAR.
    - This is because this SideNavCollapseButton is used only in the desktop mode in which
      it is rendered ON the sidebar.
*/
export const getSideNavCollapseButtonAnalyticEventPayload = (stage: string) => ({
	action: CLICKED,
	actionSubject: NAVIGATION_ITEM,
	actionSubjectId: SIDEBAR_TOGGLE,
	attributes: {
		navigationLayer: GLOBAL,
		navigationContainer: SIDEBAR,
		stage,
		navState: V4,
		toggleAction: SIDEBAR_TOGGLE_ACTION_COLLAPSE,
	},
});

/*
    - This payload is used when SideNavToggleButton's onClick event is fired.
    - Note that the navigationContainer is TOP
    - This is because this button is shown in both mobile and desktop in the top bar when it is is Expand mode.
      and when it is in Collapse mode, it is shown in top bar as well but only for mobile view.
*/
export const getSideNavToggleButtonAnalyticEventPayload = (
	isSideNavVisible: boolean | undefined,
	stage: string,
) => {
	const toggleAction = isSideNavVisible
		? SIDEBAR_TOGGLE_ACTION_COLLAPSE
		: SIDEBAR_TOGGLE_ACTION_EXPAND;
	const payload = {
		action: CLICKED,
		actionSubject: NAVIGATION_ITEM,
		actionSubjectId: SIDEBAR_TOGGLE,
		attributes: {
			navigationLayer: GLOBAL,
			navigationContainer: TOP,
			stage,
			navState: V4,
			toggleAction,
		},
	};
	return payload;
};

export const MENU_ID_ANALYTIC_EVENT_IDS: Record<ConfigurableMenuId, string> = {
	[MENU_ID_APPS]: 'appsNavigationMenuItem',
	[MENU_ID_ASSETS]: 'assetsNavigationMenuItem',
	[MENU_ID_DASHBOARDS]: 'dashboardsNavigationMenuItem',
	[MENU_ID_FILTERS]: 'filtersNavigationMenuItem',
	[MENU_ID_GOALS]: 'goalsNavigationMenuItem',
	[MENU_ID_OPERATIONS]: 'operationsNavigationMenuItem',
	[MENU_ID_OVERVIEWS]: 'overviewsNavigationMenuItem',
	[MENU_ID_PLANS]: 'plansNavigationMenuItem',
	[MENU_ID_PROJECTS]: 'projectsNavigationMenuItem',
	[MENU_ID_RECENT]: 'recentNavigationMenuItem',
	[MENU_ID_STARRED]: 'starredNavigationMenuItem',
	[MENU_ID_TEAMS]: 'teamsNavigationMenuItem',
} as const;
