// These IDs are used with the user-customisable navigation service.
export const CONFIGURABLE_MENU_ID = {
	APPS: 'jira.sidebar.apps',
	ASSETS: 'jira.sidebar.assets',
	DASHBOARDS: 'jira.sidebar.dashboards',
	FILTERS: 'jira.sidebar.filters',
	GOALS: 'jira.sidebar.goals',
	OPERATIONS: 'jira.sidebar.operations',
	OVERVIEWS: 'jira.sidebar.overviews',
	PLANS: 'jira.sidebar.plans',
	PROJECTS: 'jira.sidebar.projects',
	RECENT: 'jira.sidebar.recent',
	STARRED: 'jira.sidebar.starred',
	TEAMS: 'jira.sidebar.teams',
} as const;

export type ConfigurableMenuId = (typeof CONFIGURABLE_MENU_ID)[keyof typeof CONFIGURABLE_MENU_ID];

// The entity types are used to uniquely identify an entity instance.
// The entity's id should be in the URL
export const ENTITY_ID = {
	APP: (id: string) => `app_${id}`,
	BOARD: (id: string) => `boar_${id}`,
	DASHBOARD: (id: string) => `dash_${id}`,
	FILTER: (id: string) => `filt_${id}`,
	FORM: (id: string) => `form_${id}`,
	OVERVIEW: (id: string) => `over_${id}`,
	PLAN: (id: string) => `plan_${id}`,
	PROJECT: (id: string) => `proj_${id}`,
	PROJECT_CALENDAR: (projectKey: string) => `proj-${projectKey}-cal`,
	PROJECT_CHANGE_CALENDAR: (projectKey: string) => `proj-${projectKey}-chgcal`,
	PROJECT_ESCALATIONS: (projectKey: string) => `proj-${projectKey}-escl`,
	PROJECT_DIRECTORY: (projectKey: string) => `proj-${projectKey}-dir`,
	PROJECT_JSM_GETTING_STARTED: (projectKey: string) => `proj-${projectKey}-jsm-gtst`,
	PROJECT_KNOWLEDGE_BASE: (projectKey: string) => `proj-${projectKey}-kb`,
	PROJECT_NOTIFICATION_LOGS: (projectKey: string) => `proj-${projectKey}-nlog`,
	PROJECT_ONCALL_SCHEDULE: (projectKey: string) => `proj-${projectKey}-oncs`,
	PROJECT_REPORTS: (projectKey: string) => `proj-${projectKey}-rprt`,
	PROJECT_SERVICES: (projectKey: string) => `proj-${projectKey}-srvc`,
	PROJECT_VIEWS: (projectKey: string) => `proj-${projectKey}-views`,
	QUEUE: (id: string) => `queu_${id}`,
} as const; // We MUST use the same IDs for the same components, otherwise we run into trouble (especially with peeking / more-menu)

export const MENU_ID_APPS = CONFIGURABLE_MENU_ID.APPS;
export const MENU_ID_APP_MARKETPLACE_DISCOVER = 'app_mrkt';
export const MENU_ID_ASSETS = CONFIGURABLE_MENU_ID.ASSETS;
export const MENU_ID_ASSETS_SCHEMAS = 'asst_schm';
export const MENU_ID_ASSETS_REPORTS = 'asst_rprt';
export const MENU_ID_BOARDS_VIEW_ALL = 'board-view-all';
export const MENU_ID_DASHBOARDS = CONFIGURABLE_MENU_ID.DASHBOARDS;
export const MENU_ID_DASHBOARDS_VIEW_ALL = 'dash-view';
export const MENU_ID_DASHBOARDS_VIEW_ALL_STARRED = 'dash-view-starred';
export const MENU_ID_FILTERS = CONFIGURABLE_MENU_ID.FILTERS;
export const MENU_ID_FILTERS_SEARCH = 'filt-search';
export const MENU_ID_FILTERS_VIEW_ALL = 'filt-view-all';
export const MENU_ID_FILTERS_VIEW_ALL_STARRED = 'filt-view-starred';
export const MENU_ID_GOALS = CONFIGURABLE_MENU_ID.GOALS;
export const MENU_ID_OPERATIONS = CONFIGURABLE_MENU_ID.OPERATIONS;
export const MENU_ID_OVERVIEWS = CONFIGURABLE_MENU_ID.OVERVIEWS;
export const MENU_ID_PLANS = CONFIGURABLE_MENU_ID.PLANS;
export const MENU_ID_PLANS_VIEW_ALL = 'plan-view-all';
export const MENU_ID_PROJECT_APPS = 'proj-app';
export const MENU_ID_PROJECT_JSM_CHANGES = 'proj-jsm-chng';
export const MENU_ID_PROJECT_JSM_DIR = 'proj-dir';
export const MENU_ID_PROJECT_JSM_INCIDENTS = 'proj-jsm-inci';
export const MENU_ID_PROJECT_JSM_PROBLEMS = 'proj-jsm-prob';
export const MENU_ID_PROJECT_JSM_SERVICE_REQUESTS = 'proj-jsm-sr';
export const MENU_ID_PROJECT_JSM_POST_INCIDENT = 'proj-jsm-pir';
export const MENU_ID_PROJECT_QUEUES = 'proj-queu';
export const MENU_ID_PROJECTS = CONFIGURABLE_MENU_ID.PROJECTS;
export const MENU_ID_PROJECTS_VIEW_ALL = 'proj-view-all';
export const MENU_ID_PROJECTS_VIEW_ALL_STARRED = 'proj-view-starred';
export const MENU_ID_RECENT = CONFIGURABLE_MENU_ID.RECENT;
export const MENU_ID_STARRED = CONFIGURABLE_MENU_ID.STARRED;
export const MENU_ID_TEAMS = CONFIGURABLE_MENU_ID.TEAMS;
export const MENU_ID_YOUR_WORK = 'yrwk';
