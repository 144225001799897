import React from 'react';
import queryString from 'query-string';
import { lazyForPaint } from 'react-loosely-lazy';
import { isFedRamp } from '@atlassian/atl-context';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { TEAM_MANAGED_BOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts, createLayout } from '@atlassian/jira-route-layout';
import type { RouteContext } from '@atlassian/jira-router';
import {
	boardRelayResource,
	boardResource,
	customFiltersResource,
} from '@atlassian/jira-router-resources-board-resource';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import {
	resourceWithCondition,
	resourceWithCondition2,
} from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	softwareBoardEmbedRoute,
	softwareBoardRoute,
} from '@atlassian/jira-router-routes-software-board-routes';
import { NextGenBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-board/index.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout';
import type NextGenBoardType from '@atlassian/jira-spa-apps-next-gen-board';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyBoardPage = lazyForPaint<typeof NextGenBoardType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-next-gen-board", jiraSpaEntry: "next-gen-board" */ '@atlassian/jira-spa-apps-next-gen-board'
		),
	),
);

type Props = RouteContext;
type GetBoardIdArgs = {
	location: RouteContext['location'];
	match: RouteContext['match'];
};

const getBoardId = ({ location, match }: GetBoardIdArgs): string => {
	if (match && match.params.boardId) {
		return match.params.boardId;
	}
	const { rapidView } = queryString.parse(location.search);
	return rapidView;
};

export const SoftwareBoardComponent = ({ location, match }: Props) => (
	<LazyPage
		Page={LazyBoardPage}
		pageId={`next-gen-board-${getBoardId({ location, match })}`}
		shouldShowSpinner={false}
	/>
);

const conditionalBoardResource = resourceWithCondition(
	() => !ff('jsw.perf.critical-board-with-relay'),
	boardResource,
);

const conditionalBoardRelayResource = resourceWithCondition(
	() => ff('jsw.perf.critical-board-with-relay'),
	boardRelayResource,
);

const conditionalBoardNBTOptInResource = resourceWithCondition2(
	() => !isFedRamp(),
	nextBestTaskFeatureResource,
);

export const softwareBoardRouteEntry = createEntry(softwareBoardRoute, {
	component: SoftwareBoardComponent,
	skeleton: NextGenBoardSkeleton,
	layout: composeLayouts(
		softwareProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue'] }]),
	),
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		conditionalBoardResource,
		customFiltersResource,
		conditionalBoardRelayResource,
		conditionalBoardNBTOptInResource,
	],
	preloadOptions: {
		earlyChunks: ['software-board-early-entry'],
	},
	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyBoardPage],
	ufoName: 'next-gen-board',
	meta: {
		capability: TEAM_MANAGED_BOARD,
	},
});

export const softwareBoardEmbedRouteEntry = createEntry(softwareBoardEmbedRoute, {
	component: componentWithFG(
		'jira_board_smartlink_embed',
		SoftwareBoardComponent,
		ErrorPagesNotFound,
	),
	layout: createLayout({
		isChromeless: true,
	}),
	resources: [
		conditionalBoardNBTOptInResource,
		conditionalBoardResource,
		conditionalBoardRelayResource,
		customFiltersResource,
	],
	afterPaint: [LazyIssueApp],
	forPaint: [LazyBoardPage],
	ufoName: 'next-gen-board-embed',
	meta: {
		capability: TEAM_MANAGED_BOARD,
	},
});
