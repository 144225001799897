import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

// This is the only stage and phase that requires route matching.
// We should endeavour to use `window.location` here as it won't require a parameter.
// Any issues with SSR will be gone in the next phase.
export const getIsRouteExcludedForDogfoodingPhase1 = () => {
	if (__SERVER__) {
		return false;
	}

	const pathname = getPathname();

	if (
		!ff('polaris.sidebar-v4') &&
		(pathname.includes('/jira/polaris/') ||
			pathname.includes('/secure/JiraProductDiscoveryEmbedded') ||
			pathname.includes('/jira/discovery/'))
	) {
		return true;
	}

	if (!fg('jpd-sidebar-v4-for-roadmaps') && pathname.includes('/jira/polaris/roadmaps')) {
		return true;
	}

	return false;
};

const getPathname = () => window.location.pathname;
