/**
 * @generated SignedSource<<d10ce1275daaa02083c1f628edaa5d5e>>
 * @relayHash 55add9071023e2d75d07e2339d42f61a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c3c8263b71900e2de15bdc9d30eeb47b3d16867b246511869ebdd94dede3a46a

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainIssueAggQuery } from './mainIssueAggQuery.graphql';

import issueViewRelayAssigneeFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-assignee.relayprovider';
import issueViewRelayParentFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-parent';
import issueViewRelayReporterFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-reporter.relayprovider';
import issueViewRelayTimeTrackingFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-time-tracking.relayprovider';
import issueViewRelayUserFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-user.relayprovider';

const node: PreloadableConcreteRequest<mainIssueAggQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "c3c8263b71900e2de15bdc9d30eeb47b3d16867b246511869ebdd94dede3a46a",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "jira",
            "issueByKey",
            "attachments"
          ]
        }
      ]
    },
    "name": "mainIssueAggQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent": issueViewRelayParentFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider": issueViewRelayTimeTrackingFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider": issueViewRelayAssigneeFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider": issueViewRelayReporterFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider": issueViewRelayUserFieldFlag_provider
    }
  }
};

export default node;
