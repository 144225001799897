// eslint-disable-next-line jira/restricted/@atlassian/jira-router
import { createResource } from '@atlassian/jira-router';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import fetchWorkspaceId from './get-data';

export const RESOURCE_TYPE_SERVICEDESK_WORKSPACE_CONTEXT = 'JSM_INSIGHT_WORKSPACE_CONTEXT';

export const workspaceResource = createResource<WorkspaceId | null>({
	type: RESOURCE_TYPE_SERVICEDESK_WORKSPACE_CONTEXT,
	getKey: () => 'state',
	getData: async (_, routerContext) => {
		const workspaceId = await fetchWorkspaceId(_, routerContext);
		return workspaceId;
	},
	maxAge: Infinity,
});
