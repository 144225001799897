import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ff } from '@atlassian/jira-feature-flagging';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { staticSidebarDefaultPINFiltersResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/project-filters/index.tsx';
import { sidebarProjectIssuesResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/project-sidebar-common/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import type { Route } from '@atlassian/jira-router';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common';
import { isNinGlobalScopeEnrolmentEnabledResource } from '@atlassian/jira-router-resources-is-nin-global-scope-enrolment-enabled';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import {
	issueNavigatorRelayResource,
	issueNavigatorFilterRelayResource,
} from '@atlassian/jira-router-resources-software-requests/src/services/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
// TO-DO Need a copy of  this LazyIssueApp in Requests page
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import {
	softwareRequestsRoute,
	softwareRequestsClassicRoute,
} from '@atlassian/jira-router-routes-software-requests-routes';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout';
import { LazyClassicRequestsPage, LazyNextGenRequestsPage, LazyRequests } from './ui';

const getCommonProjectResources = () => [
	issueNavigatorRelayResource,
	issueNavigatorFilterRelayResource,
	...getNavigationResources(),
	...getNavigationSidebarProjectResource(),
	...getBusinessProjectResources(),
	// TODO: create skeleton for requests
	resourceWithCondition2(() => ff('nin.global-scope_aqec8'), sidebarProjectIssuesResource),
	resourceWithCondition2(
		() => ff('nin.global-scope_aqec8'),
		isNinGlobalScopeEnrolmentEnabledResource,
	),
	staticSidebarDefaultPINFiltersResource,
];

const getCommonProject = () => ({
	apdexIgnoreParams: ['issueKey'],
	// TO-DO change this to Request page skelton after creating skeleton for Request page
	skeleton: ProjectIssueNavigatorSkeleton,
	resources: [...getCommonProjectResources()],
	forPaint: [LazyAtlassianNavigation, LazyRequests],
	afterPaint: [LazyIssueApp],
});

export const softwareRequestsRouteEntry: Route = createEntry(softwareRequestsRoute, {
	...getCommonProject(),
	component: LazyNextGenRequestsPage,
	layout: composeLayouts(softwareProjectLayout),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},

	ufoName: 'next-gen-requests',
});

export const softwareRequestsClassicRouteEntry: Route = createEntry(softwareRequestsClassicRoute, {
	...getCommonProject(),
	component: LazyClassicRequestsPage,
	layout: composeLayouts(softwareProjectLayout),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarSoftwareClassic,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},

	ufoName: 'classic-requests',
});
