import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { CLASSIC_BOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import type { Route } from '@atlassian/jira-router';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import {
	getNavigationResources,
	getUserBoardNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { LazyIssueApp } from '@atlassian/jira-router-routes-common/src/async.tsx';
import { rapidboardBoardRoute } from '@atlassian/jira-router-routes-software-classic-rapid-board-routes';
import {
	LazyRapidBoard,
	LazyRapidBoardApps,
	RapidBoardSpaPage,
} from '@atlassian/jira-router-routes-user-board-entries/src/async';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/index.tsx';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';

export const legacyRapidBoardRouteEntry: Route = createEntry(rapidboardBoardRoute, {
	ufoName: 'classic-board',
	component: RapidBoardSpaPage,
	skeleton: ClassicBoardSkeleton,

	layout: composeLayouts(
		genericProjectLayout,
		embeddedIssuePreloadLayoutBuilder([
			{ query: ['selectedIssue', 'view=detail'] },
			{ query: ['selectedIssue', 'modal=detail'] },
		]),
	),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: classicBoardSidebars,
		horizontal: AsyncHorizontalSoftwareProjectNav,
		onlyShowHorziontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProjectResource(),
		...getRapidboardResources(),
		getRapidBoardDataResource(),
		...getUserBoardNavigationResources(),
	],

	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyRapidBoard, LazyRapidBoardApps],

	preloadOptions: {
		earlyChunks: ['rapidboard-early-entry'],
	},

	meta: {
		capability: CLASSIC_BOARD,
	},

	earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,
});
