import { type Dispatch, type SetStateAction, useContext } from 'react';

import {
	SetSideNavDesktopVisibility,
	SetSideNavMobileVisibility,
	SideNavDesktopVisibility,
	SideNavMobileVisibility,
} from './context';
export const useSideNavVisibility = ({
	defaultCollapsed = false,
}: {
	/**
	 * The default visibility state of the side nav on desktop screens.
	 * This value will be used if the visibility state is not set in context yet, e.g. during SSR.
	 */
	defaultCollapsed?: boolean;
} = {}): {
	visibleOnDesktop: boolean;
	setVisibleOnDesktop: Dispatch<SetStateAction<boolean | null>>;
	visibleOnMobile: boolean;
	setVisibleOnMobile: Dispatch<SetStateAction<boolean>>;
} => {
	const visibleOnDesktop = useContext(SideNavDesktopVisibility);
	const setVisibleOnDesktop = useContext(SetSideNavDesktopVisibility);
	const visibleOnMobile = useContext(SideNavMobileVisibility);
	const setVisibleOnMobile = useContext(SetSideNavMobileVisibility);

	return {
		visibleOnDesktop: visibleOnDesktop === null ? !defaultCollapsed : visibleOnDesktop,
		visibleOnMobile,
		setVisibleOnDesktop,
		setVisibleOnMobile,
	};
};
