import type {
	CalendarIssueEventFields,
	CalendarScopeBoard,
	CalendarViewMode,
	CalendarWeekStart,
} from '@atlassian/jira-calendar/src/controllers/calendar-store/types.tsx';
import type { RouteNames } from '@atlassian/jira-common-constants/src/spa-routes';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import parameters from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery$parameters';
import type {
	JiraViewScopeInput,
	JiraCalendarViewConfigurationInput,
	JiraCalendarIssuesInput,
	JiraCalendarVersionsInput,
	JiraCalendarSprintsInput,
	JiraCalendarMode,
	JiraCalendarWeekStart,
} from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

function toBoardAri({ siteId, boardId }: { siteId: string; boardId: string }) {
	return `ari:cloud:jira-software:${siteId}:board/${boardId}`;
}

function toMidnightUTCString(date: Date) {
	const utcMilliseconds = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
	return new Date(utcMilliseconds).toISOString();
}

function toDate(date: string | null | undefined): Date {
	if (!date) {
		return new Date();
	}
	try {
		const _date = new Date(date);
		return new Date(_date.getFullYear(), _date.getMonth(), _date.getDate());
	} catch (e) {
		return new Date();
	}
}

/* Given the current constraints that prevent importing route name constants into the entrypoint,
we are re-defining these constants here in order to perform a route name check.
Consider breaking this entrypoint per route to avoid these types of checks and dependencies. */

const SOFTWARE_CALENDAR: RouteNames = 'software-calendar';
const SOFTWARE_CALENDAR_EMBED: RouteNames = 'software-calendar-embed';

const TEAM_MANAGED_CALENDAR_ROUTES: string[] = [SOFTWARE_CALENDAR, SOFTWARE_CALENDAR_EMBED];

function createScope({ context, tenantContext }: EntryPointRouteParams): CalendarScopeBoard {
	const { cloudId } = tenantContext;

	const isTMP = TEAM_MANAGED_CALENDAR_ROUTES.includes(context.route.name);

	return {
		type: 'board',
		boardId: toBoardAri({
			siteId: cloudId,
			boardId: String(context.match.params.boardId),
		}),
		projectKeys: isTMP ? [String(context.match.params.projectKey)] : [],
	};
}

function buildScopeInput(
	{ tenantContext }: EntryPointRouteParams,
	scope: CalendarScopeBoard,
): JiraViewScopeInput {
	const { cloudId } = tenantContext;

	const scopeInput = {
		ids: [scope.boardId],
	};

	if (scope.projectKeys && scope.projectKeys.length > 0) {
		return {
			...scopeInput,
			projectKeys: {
				cloudId,
				keys: scope.projectKeys,
			},
		};
	}

	return scopeInput;
}

function toViewMode(mode: string): JiraCalendarMode {
	switch (mode) {
		case 'week':
			return 'WEEK';
		case 'day':
			return 'DAY';
		default:
			return 'MONTH';
	}
}

function toWeekStart(weekStart: string): JiraCalendarWeekStart {
	switch (weekStart) {
		case 'monday':
			return 'MONDAY';
		case 'saturday':
			return 'SATURDAY';
		default:
			return 'SUNDAY';
	}
}

function buildConfigurationInput({
	selectedDate,
	viewMode,
	weekStartsOn,
	startDateField,
	endDateField,
}: {
	selectedDate: Date;
	viewMode: CalendarViewMode;
	weekStartsOn: CalendarWeekStart;
	startDateField: string;
	endDateField: string;
}): JiraCalendarViewConfigurationInput {
	return {
		date: toMidnightUTCString(selectedDate),
		mode: toViewMode(viewMode),
		weekStart: toWeekStart(weekStartsOn),
		startDateField,
		endDateField,
	};
}

function buildIssuesSearchInput(): JiraCalendarIssuesInput {
	return { additionalFilterQuery: '' };
}

function buildUnscheduledIssuesSearchInput(): JiraCalendarIssuesInput {
	return { additionalFilterQuery: '' };
}

function buildVersionsSearchInput(): JiraCalendarVersionsInput {
	return { versionStatuses: ['RELEASED', 'UNRELEASED'] };
}

function buildSprintsSearchInput(): JiraCalendarSprintsInput {
	return { sprintStates: ['ACTIVE', 'FUTURE', 'CLOSED'] };
}

export const calendarEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-jira-calendar" */ './src'),
	),
	getPreloadProps: (entryPointRouteParams: EntryPointRouteParams) => {
		const { context, tenantContext } = entryPointRouteParams;

		const { cloudId } = tenantContext;
		const scope = createScope(entryPointRouteParams);

		const { date } = context.query;
		const selectedDate = toDate(date);

		const boardId = String(context.match.params.boardId);

		const viewMode: CalendarViewMode = 'month';
		const weekStartsOn: CalendarWeekStart = 'sunday';
		const startDateField = 'startdate';
		const endDateField = 'duedate';
		const issueEventFields: CalendarIssueEventFields = {
			startDateField,
			endDateField,
		};

		return {
			queries: {
				calendarData: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters,
					variables: {
						cloudId,
						scopeInput: buildScopeInput(entryPointRouteParams, scope),
						configurationInput: buildConfigurationInput({
							selectedDate,
							viewMode,
							weekStartsOn,
							startDateField,
							endDateField,
						}),
						issuesSearchInput: buildIssuesSearchInput(),
						unscheduledIssuesSearchInput: buildUnscheduledIssuesSearchInput(),
						versionsSearchInput: buildVersionsSearchInput(),
						sprintsSearchInput: buildSprintsSearchInput(),
						skipSprintSearch: false,
						skipVersionSearch: false,
						viewId: null,
						queryV2Enabled: ff('update-calendar-query_n6opw'),
						filterProjectContextEnabled: fg('calendar_set_filter_context'),
					},
				},
			},
			entryPoints: {},
			extraProps: {
				scope,
				selectedDate,
				viewMode,
				weekStartsOn,
				issueEventFields,
				boardId,
			},
		};
	},
});
